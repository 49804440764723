// Intro and extra setup
.mcontainer {
  max-width: 96vw;
  max-height: 90vh;
  max-height: calc(var(--vh, 1vh) * 90);

  .button {
    @apply rounded bg-gray-500 p-1.5 text-gray-100 drop-shadow-md flex items-center justify-center;
  }
  .button:active {
    @apply drop-shadow-lg;
  }
  .button.circle {
    @apply rounded-full flex items-center justify-center h-14 w-14 p-2.5;
  }
  .button.accent {
    @apply bg-green-dark;
  }
  .button.circle.float-bottom-right {
    @apply bottom-4;
    right: calc(var(--scrollbar-width, 0px) + .5rem);
  }
  .button.animate {
    transition: opacity .3s ease;
    opacity: 0;
  }
  .button.animate.show {
    opacity: 1;
  }
}

@media (min-height: 768px) {
  .mcontainer {
    //max-height: 90vh;
  }
}


@media (min-height: 992px) {
  .mcontainer {
    max-height: 56rem;
  }
}

@media (min-width: 768px) {
  .mcontainer {
    max-width: 650px;
  }
}

@media (min-width: 992px) {
  .mcontainer {
    max-width: 850px;
  }
}

@media (min-width: 1200px) {
  .mcontainer {
    max-width: 1100px;
  }
}


@media (min-width: 1400px) {
  .mcontainer {
    max-width: 1300px;
  }
}


@media (min-width: 1600px) {
  .mcontainer {
    max-width: 1500px;
  }
}

.info-mobile {
  transition: left .3s ease;
  left: 100%;
}

.info-mobile.show {
  display: block;
  left: 0;
}

